import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreditsIcon from '../icons/CreditsIcon';
import DateIcon from '../icons/DateIcon';
import LocationIcon from '../icons/LocationIcon';
import PriceIcon from '../icons/PriceIcon';
import ShareIcon from '../icons/ShareIcon';
import LikeBlueIcon from '../icons/LikeBlueIcon';
import { likeConference, unlikeConference } from '../../utility/commonUtil';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

import {
  getFormattedDateInTz,
  getLocationString,
  openInNewTab,
  regex1000To15x9,
} from '../../utility/commonUtil';
import ShareModal from '../share-modal/ShareModal';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { localAlertAction } from '../../redux/alert/alertAction';
import LocalAlert from '../alert/LocalAlert';
import SubmitButtonWithLoader from '../button/SubmitButtonWithLoader';
import CloseIcon from '../icons/CloseIcon';
import LikeInactiveIcon from '../icons/LikeInactiveIcon';
import Modal from '../modal/Modal';
import PotentialUserForm from '../register/PotentialUser';
import './ConfDetails.scss';

const potentialUserInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  profession: '',
  country: '',
  state: '',
  city: '',
  countryCode: '',
  mobile: '',
};

const potentialUserFormValidation = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
  lastName: yup
    .string()
    .trim()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),

  profession: yup
    .string()
    .oneOf(
      [
        'physician',
        'physicianAssistant',
        'nursePractitioner',
        'dentist',
        'nurse',
        'pharmacist',
        'physicalTherapist',
        'occupationalTherapist',
        'speechTherapist',
        'respiratoryTherapist',
        'dietitian',
        'socialWorker',
        'caseManagement',
        'other',
      ],
      'Please choose a profession'
    )
    .required('Required'),
  email: yup
    .string()
    .email('Please provide a valid email')
    .required('Required'),
  countryCode: yup.string().required('Required'),

  mobile: yup
    .string()
    .matches(regex1000To15x9, 'Eenter valid mobile number.')
    .required('Required'),
});

export default function BookingCard({
  title,
  organizer,
  hostedBy,
  startDate,
  timezone,
  endDate,
  mode,
  city,
  currency,
  basePrice,
  credits,
  confId,
  bookingTickets,
  apiIsLiked,
  preview,
  scraped,
  url,
}) {
  const [showPopUp, setShowPopUp] = useState(false);
  const [showPotentialUserForm, setShowPotentialUserForm] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;

  const onSubmit = async (values, action) => {
    try {
      let formData = {
        user: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: values?.email,
          country: values?.country,
          state: values?.state,
          city: values?.city,
          confId: confId,
          profession: values?.profession,
          mobile: values?.mobile,
          countryCode: values.countryCode,
        },
      };
      // await addRecentlyViewed(conferenceId);
      const response = await api.post('/common/potentialUsers', formData);

      if (response) {
        openInNewTab(response?.data?.data?.conference?.url);
        setShowPotentialUserForm(false);
      }
    } catch (err) {
      dispatch(
        localAlertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const addRecentlyViewed = async (Id) => {
    const data = {
      recentlyViewedConferenceDetails: {
        conferenceId: Id,
      },
    };
    try {
      await api.post('homePage/recentlyviewed', data);
    } catch (err) {}
  };

  const like = (confId, userId, setIsLiked) => {
    if (!isLiked && isAuthenticated && user) {
      likeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction('Please login to like a conference.', 'info'));
    }
  };

  const unlike = (confId, userId, setIsLiked) => {
    if (isLiked && isAuthenticated && user) {
      unlikeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction('Please login to unlike a conference.', 'info'));
    }
  };

  useEffect(() => {
    setIsLiked(apiIsLiked);
  }, [apiIsLiked]);

  return (
    <div className="conf-details-card">
      <div className="mb-32">
        <h4 className="mb-14">{title}</h4>
        <p className="caption-1-regular-gray3 mb-10">
          {hostedBy && 'by '}
          {organizer === 'user'
            ? `${hostedBy?.user?.firstName} ${hostedBy?.user?.lastName}`
            : hostedBy?.organization?.name}
        </p>
        {/* <button className="cd-follow-button">Follow</button> */}
        <div className="icon-grid-card mt-32">
          <div className="flex-vc mb-12">
            <DateIcon className="icon-sm mr-16" />
            <p className="body-regular-gray3">
              <span>Start:</span>{' '}
              <span>{getFormattedDateInTz(startDate, timezone)}</span>
              {/* {getFormattedDateInTz(endDate, timezone)} */}
            </p>
          </div>
          <div className="flex-vc mb-12">
            <DateIcon className="icon-sm mr-16" />
            <p className="body-regular-gray3">
              <span>End: </span>
              <span>{getFormattedDateInTz(endDate, timezone)}</span>
            </p>
          </div>
          <div className="flex-vc mb-12">
            <LocationIcon className="icon-sm mr-16" />
            <p className="body-regular-gray3">
              {getLocationString(mode, city)}
            </p>
          </div>
          <div className="flex-vc mb-12">
            <CreditsIcon className="icon-sm mr-16" />
            <p className="body-regular-gray3">
              {' '}
              {credits?.length > 0
                ? `${credits[0].creditId?.name} - ${credits[0]?.quantity}`
                : 'Credits not added'}
            </p>
          </div>
          <div className="flex-vc mb-12">
            <PriceIcon className="icon-sm mr-16" />
            <p
              className="avenir-roman-18"
              style={{
                fontWeight: 900,
                lineHeight: 1.33,
                letterSpacing: 0.2,
              }}
            >
              {currency && basePrice > 0
                ? `${currency} -  
                    ${basePrice} Onwards`
                : currency && basePrice === 0
                ? 'Free'
                : 'Price not availabe'}
            </p>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div className="bottom-bar">
          <i
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (preview) {
                return;
              }
              setShowPopUp(!showPopUp);
            }}
          >
            <ShareIcon className="icon-sm" />
          </i>
          <div>
            {isLiked ? (
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (preview) {
                    return;
                  }
                  unlike(confId, user?._id, setIsLiked);
                }}
              >
                <LikeBlueIcon className="icon-sm" />
              </i>
            ) : (
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (preview) {
                    return;
                  }
                  like(confId, user?._id, setIsLiked);
                }}
              >
                <LikeInactiveIcon className="icon-size" />
                {/* <LikeBlueIcon className="icon-sm" /> */}
              </i>
            )}
          </div>
          <div>
            <button
              onClick={() => {
                if (preview) {
                  return;
                }
                if (scraped) {
                  if (user) {
                    addRecentlyViewed(confId);
                    openInNewTab(url);
                  } else {
                    openInNewTab(url);
                  }
                } else {
                  navigate(`/book-conference/${confId}`);
                }
              }}
              type="button"
              className="button button-green"
            >
              Book
            </button>
          </div>
        </div>
        {showPopUp && (
          <ShareModal
            setShowPopUp={setShowPopUp}
            showPopUp={showPopUp}
            confId={confId}
            cardClass="left-share-card"
            buttonClass="share-btn"
          />
        )}
        {showPotentialUserForm && (
          <>
            <Modal onDismiss={() => setShowPotentialUserForm(false)}>
              <div className="modalx-header">
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowPotentialUserForm(false)}
                >
                  <CloseIcon className="icon-size" fill="#c4c4c4" />
                </i>
              </div>
              <Formik
                initialValues={potentialUserInitialValues}
                validationSchema={potentialUserFormValidation}
                onSubmit={onSubmit}
              >
                {(props) => {
                  const { isSubmitting } = props;
                  return (
                    <Form className="form-type-1  p-16">
                      <div>
                        <h4 className="text-align-center mb-8">
                          Enter details to view Conference
                        </h4>
                        <LocalAlert />
                        <PotentialUserForm props={props} />
                      </div>
                      <div>
                        <SubmitButtonWithLoader
                          isSubmitting={isSubmitting}
                          text={'View Conference'}
                          className="button button-primary"
                          fullWidth={true}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}
